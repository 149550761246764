// projectData.js
const projectData = [
  {
    id: 0,
    title: 'I gave a seminar talk for Brown Visual Computing.',
    date: 'NOV 2024',
    venue: 'My talk was titled "Enabling Design with AI through Interactive Techniques for Multimodal Integration"-- thank you so much to Brown University Computer Science for giving me the opportunity to present my work.',
    links: {
      paperlink:"",
      pg: "",
      codelink: "",
 demolink: "",
      blogpostlink: "",
      video: "https://www.youtube.com/live/lEbKPXtbd4g?si=sA1ZRaIAZtTfNSQG",
      img_src: "./images/latent_preview.gif",
       demo: '',
      source: '',
    },
  },
  {
    id: 1,
    title: 'Started student researcher internship at Google Deepmind.',
    date: 'SEP 2024',
    venue: "I am working on the People + AI Research Team, where I am fortunate to work with Alex Fiannaca, Carrie Cai, and Michael Terry. I am back in Seattle. :) ",
    links: {
      paperlink:"",
      pg: "",
      codelink: "",
 demolink: "",
      blogpostlink: "",
      video: "",
      img_src: "./images/rainbow.jpg",
       demo: '',
      source: '',
    },
  },
  {
    id: 0,
    title: 'Ran a workshop using Generative Disco for an NGO called STEM From Dance.',
    date: 'July 2024',
    venue: 'Disco was presented to 171 girls 8-18 over 3 guest lectures for STEM From Dance, an NGO that reaches out to underrepresented girls through programs that incorporate dance, physical computing, and computer science. 20 students participated in a workshop, creating text-to-video music videos at the Barnard Movement Lab. Collaborating with STEM From Dance and since 2021 has been one of the most rewarding experiences of my PhD.',
    links: {
      paperlink:"",
      pg: "",
      codelink: "",
      demolink: "",
      blogpostlink: "",
      video: "",
      img_src: "./images/disco_workshop.jpg",
       demo: '',
      source: '',
    },
  },
  {
    id: 0,
    title: 'Design Guidelines will be featured in a textbook Introduction to Human-Computer Interaction.',
    date: '2024',
    venue: 'Introduction to Human-Computer Interaction is forthcoming in Oxford University Press 2024.',
    links: {
      paperlink:"",
      pg: "https://introductiontohci.org/",
      codelink: "",
      demolink: "",
      blogpostlink: "",
      video: "",
      img_src: "./images/designguidelines.jpeg",
       demo: '',
      source: '',
    },
  },
  {
    id: 0,
    title: 'GPTing Papers',
    date: 'MAY 2024',
    venue: 'Some thoughts I had while using an LLM to understand a computational models paper from cognitive science.',
    links: {
      paperlink:"",
      pg: "",
      codelink: "",
      demolink: "",
      blogpostlink: "/computational_models",
      video: "",
      img_src: "https://media2.giphy.com/media/5dYqPVcoq9mKuxleyR/200w.gif?cid=6c09b952f8f607ppa5nvos1pww9bbzswakr03af12aaw9eyx&ep=v1_gifs_search&rid=200w.gif&ct=g",
      demo: '',
      source: '',
    },
  },
    // {
    //     id: 0,
    //     title: 'Doggo',
    //     date: 'MAY 2024',
    //     venue: '',
    //     links: {
    //       paperlink:"",
    //       pg: "",
    //       codelink: "",
    //       demolink: "",
    //       blogpostlink: "",
    //       video: "",
    //       img_src: "./images/dog2.jpg",
    //       demo: '',
    //       source: '',
    //     },
    //   },
    {
      id: 0,
      title: '3DALL-E is patented! ',
      authors: ' Patent US20240104275A1 Inventors: Vivian Liu, Jo Vermeulen, George Fitzmaurice, Justin Matejka',
      date: 'APRIL 2024',
      venue: ' I wrote in my NSF Graduate Research Fellowship application that I would like to be an inventor. :) It happened!',
      links: {
        paperlink:"https://patents.google.com/patent/US20240104275A1/en",
        pg: "",
        codelink: "",
        demolink: "",
        blogpostlink: "",
        video: "",
        img_src: "./images/patent.png",
        demo: '',
        source: '',
      },
    },
      {
        id: 1,
        title: '3DALL-E tech transferred into plugin for Fusion 360!',
        authors: 'Autodesk Research',
        date: 'APRIL 2024',
        venue: "I am deeply grateful to Jo, George, Justin, and other collaborators at Autodesk who pushed my intern project forward to into product. The first thing I learned in undergrad besides coding was CAD, so this is a huge highlights of my PhD--to come full circle. Will post a more in-depth blog post later. :)  ",
        links: {
          paperlink:"",
          pg: "",
          codelink: "",
          blogpostlink: "",
          demolink: "https://apps.autodesk.com/FUSION/en/Detail/Index?id=8558451727109537930",
          video: "",
          img_src: "./images/butterfly.png",
          demo: '',
          source: '',
        },
      },
      {
        id: 2,
        title: 'Finished internship with Adobe Research.',
        description: 'Description for Project 2.',
        authors: 'Adobe Research',
        date: 'APRIL 2024',
        venue: 'I am sincerely thankful for the opportunity I had to work at Adobe and for all the mentorship and help I received from Rubaiat, Li-Yi, Matt, Tim, Seth, Valentina, Wil and many others. :) I spent 10 months at Adobe, first in Seattle and then in NYC.',
        links: {
          paperlink: "",
          pg: "/logomotion",
          codelink: "",
          demolink:"",
          blogpostlink: "",
          video: "",
          img_src: "./images/sunset.png",
          source: '',
        },
      },
      {
        id: 2,
        title: 'Gave a talk for Spark Animation 2022 about AI and Animation',
        authors: '',
        date: 'NOV 2022',
        venue: 'I along with Yingjie Wang gave a talk for Spark Computer Graphics Society on "Text-to-Image AI for Animation" in the Artificial Intelligence in Animation track of SPARK ANIMATION 2022.',
        links: {
          paperlink: "",
          pg: "",
          codelink: "",
          demolink:"",
          blogpostlink: "",
          video: "https://www.youtube.com/watch?v=N_NcjC7ckYo&ab_channel=SPARKCG",
          img_src: "./images/spark.jpg",
          source: '',
        },
      },
      {
        id: 2,
        title: '3DALL-E highlighted in Tanner Lectures on AI and Human Values',
        description: 'Description for Project 2.',
        authors: '',
        date: 'NOV 2022',
        venue: 'Eric Horvitz, Chief Science Officer at Microsoft, highlighted 3DALL-E in his keynote for the Tanner Lecture on Artificial Intelligence and Human Values',
        links: {
          paperlink: "",
          pg: "",
          codelink: "",
          demolink:"",
          blogpostlink: "",
          video: "https://www.youtube.com/watch?v=vsewugyXYXI&ab_channel=MichiganEngineering",
          img_src: "./images/3DALLE/3dalle_square.jpg",
          source: '',
        },
      },

    ];
    
    export default projectData;