// projectData.js
const projectData = [
  {
    id: 0,
    title: 'LogoMotion: Visually-Grounded Code Generation for Content-Aware Animation',
    authors: 'Vivian Liu, Rubaiat Habib Kazi, Li-Yi Wei, Matt Fisher, Timothy Langlois, Seth Walker, Lydia Chilton',
    venue: 'Under Submission',
    links: {
      paperlink:"http://arxiv.org/abs/2405.07065",
      pg: "/logomotion",
      codelink: "",
      demolink: "",
      video: "https://youtu.be/Jo9opkMH7iY",
      img_src: "./logomotion_gifs/cat1.gif",
      demo: './images/disco.gif',
      source: 'https://github.com/example/project1',
    },
  },
    {
      id: 1,
      title: 'Generative Disco: Text-to-Video for Music Visualization',
      authors: 'Vivian Liu, Tao Long, Jenny Ma, Nathan Raw, Lydia Chilton',
      venue: 'HICCS 2025',
      links: {
        paperlink:"https://arxiv.org/abs/2304.08551",
        pg: "/disco",
        codelink: "https://github.com/hellovivian/generative-disco",
        demolink: "https://huggingface.co/spaces/vivlavida/generative-disco",
        video: "https://www.youtube.com/watch?v=q22I53jHbuU&list=PLa3syNOZMtayyCxdYGE5T2tEF-Yc531tm&index=17&ab_channel=VivianL",
        img_src: "./images/disco.gif",
        demo: './images/disco.gif',
        source: 'https://github.com/example/project1',
      },
    },
    {
      id: 2,
      title: '3DALL-E: Integrating Text-to-Image AI into 3D Design Workflows',
      description: 'Description for Project 2.',
      authors: 'Vivian Liu, Jo Vermeulen, George Fitzmaurice, Justin Matejka',
      venue: 'DIS 2023',
      links: {
        paperlink: "https://dl.acm.org/doi/10.1145/3563657.3596098",
        pg: "/3DALLE",
        codelink: "",
        demolink:"https://apps.autodesk.com/FUSION/en/Detail/Index?id=8558451727109537930",
        video: "https://www.youtube.com/watch?v=c45gIiAEWZU&ab_channel=VivianL",
        img_src: "./images/3DALLE/3DALLE.gif",
        source: 'https://github.com/example/project2',
      },
    },
    {
      id: 3,
      title: 'Opal: Multimodal Image Generation for News Illustration',
      description: '',
      authors: 'Vivian Liu, Han Qiao, Lydia Chilton',
      venue: 'UIST 2022',
      links: {
        pg:"/opal",
        img_src: "./images/opal.png",
        demolink:"",
        codelink:"",
        video: "https://youtu.be/grrdo6TtV88",
        paperlink: "https://arxiv.org/pdf/2204.09007.pdf",
      },
    },
    {
      id: 4,
      title: 'Design Guidelines for Prompt Engineering Text-to-Image Generations',
      authors: 'Vivian Liu, Lydia Chilton',
      description: 'Description',
      venue: 'CHI 2022',
      links: {
        img_src: "./images/designguidelines.jpeg",
        pg: "/designguidelines",
        demolink:"",
        video:"https://www.youtube.com/watch?v=7-XnIuH8r3U&ab_channel=ACMSIGCHI",
        codelink:"",
        paperlink:"https://dl.acm.org/doi/abs/10.1145/3491102.3501825",
        slides: 'https://docs.google.com/presentation/d/1krOov5w_c1byOoDsgwNDHmfytrqofac32M-CzWx6f5E/edit#slide=id.gceeec265cf_0_4'
      },
    }
  ];
  
  export default projectData;