// fullProjectData.js
const projectData = [

  {
    id: 0,
    title: 'DynEx: Dynamic Code Synthesis with Structured Design Exploration for Accelerated Exploratory Programming',
    authors: 'Jenny Ma, Karthik Sreedhar, Vivian Liu, Sitong Wang, Pedro Alejandro Perez, Riya Sahni, Lydia B. Chilton',
    description: 'Description',
    venue: 'Under Submission',
    links: {
      img_src: "./images/dynex.png",
      projectpage: "https://jennygzma.github.io/#/dynex",
      paperlink: "https://arxiv.org/abs/2410.00400",
      demolink: "",
      video: "https://www.youtube.com/watch?v=ZY5WNgjo7as&ab_channel=jny",
      codelink: "",


    },
  },
  {
    id: 1,
    title: 'MIRACLE: An Online, Explainable Multimodal Interactive Concept Learning System',
    authors: 'Ansel Blume, Khanh Duy Nguyen, Zhenhailong Wang, Yangyi Chen, Michal Shlapentokh-Rothman, Xiaomeng Jin, Jeonghwan Kim, Zhen Zhu, Jiateng Liu, Kuan-Hao Huang, Mankeerat Sidhu, Xuanming Zhang, Vivian Liu, Raunak Sinha, Te-Lin Wu, Abhay Zala, Elias Stengel-Eskin, Da Yin, Yao Xiao, Utkarsh Mall, Zhou Yu, Kai-Wei Chang, Camille Cobb, Karrie Karahalios, Lydia Chilton, Mohit Bansal, Nanyun Peng, Carl Vondrick, Derek Hoiem, Heng Ji',
    description: 'Description',
    venue: 'ACMMM 2024 Technical Demos and Videos Program',
    links: {
      img_src: "./images/miracledemo.png",
      projectpage: "",
      paperlink: "",
      demolink: "",
      video: "",
      codelink: "",


    },
  },
  {
    id: 2,
    title: 'CLIP-Sculptor: Zero-Shot Generation of High-Fidelity and Diverse Shapes from Natural Language',
    authors: 'Aditya Sanghi, Rao Fu, Vivian Liu, Karl Willis, Hooman Shayani, Amir Hosein Khasahmadi, Srinath Sridhar, Daniel Ritchie',
    description: 'Description',
    venue: 'CVPR 2023',
    links: {
      img_src: "./images/airplane.gif",
      projectpage: "https://ivl.cs.brown.edu/#/projects/clip-sculptor",
      paperlink: "https://openaccess.thecvf.com/content/CVPR2023/papers/Sanghi_CLIP-Sculptor_Zero-Shot_Generation_of_High-Fidelity_and_Diverse_Shapes_From_Natural_CVPR_2023_paper.pdf",
      demolink: "",
      video: "https://www.youtube.com/watch?v=vUhNdZyeoxI&ab_channel=AdityaSanghi",
      codelink: "",


    },
  },
  {
    id: 3,
    title: 'Sparks: Inspiration for Science Writing using Language Models',
    authors: 'Katy Gero, Vivian Liu, Lydia Chilton',
    description: 'Description',
    venue: 'DIS 2022',
    links: {
      img_src: "./images/sparks.png",
      projectpage: "",
      paperlink: "https://dl.acm.org/doi/abs/10.1145/3532106.3533533",
      demolink: "",
      video: "",
      codelink: "",

    },
  },
  {
    id: 4,
    title: 'Initial Images: Improving Subject Representation in AI Generated Art: Design Guidelines for Using Image Prompts with Text-to-Image Generative Models',
    authors: 'Han Qiao, Vivian Liu, Lydia Chilton',
    venue: 'Creativity and Cognition 2022',
    links: {
      img_src: "./images/initimages.jpg",
      projectpage: "",
      paperlink: "",
      demolink: "",
      video: "",
      codelink: "",
      paperlink: "https://dl.acm.org/doi/abs/10.1145/3527927.3532792",
    },
  },
  {
    id: 5,
    title: 'What Makes Tweetorials Tick: How Experts Communicate Complex Topics on Twitter.',
    authors: 'Katy Gero, Vivian Liu, Lydia Chilton',
    venue: 'CSCW 2022',
    links: {
      img_src: "./images/techtweets.png",
      projectpage: "http://language-play.com/tech-tweets/",
      paperlink: "",
      demolink: "",
      video: "",
      codelink: "",
      paperlink: "https://dl.acm.org/doi/abs/10.1145/3479566",
    },
  },

  {
    id: 6,
    title: 'VisiFit: Structuring Iterative Improvement for Novice Designers',
    authors: 'Lydia B. Chilton, Ecenaz Jen Omen, Sam Ross, Vivian Liu',
    venue: 'CHI 2021',
    links: {
      img_src: "./images/visifit.png",
      projectpage: "",
      demolink: "",
      video: "https://www.youtube.com/watch?v=5GJwkGhb6DI",
      codelink: "",
      paperlink: "https://dl.acm.org/doi/abs/10.1145/3479566",
    },
  },


  {
    id: 7,
    title: 'Interacting with Literary Style Through Computational Tools',
    authors: 'Sarah Sterman, Evey Huang, Vivian Liu, Eric Paulos',
    venue: 'CHI 2020',
    links: {
      img_src: "./images/visualstyle.png",
      projectpage: "",
      demolink: "",
      video: "https://youtu.be/hJQ-NZBWD38",
      codelink: "",
      paperlink: "https://dl.acm.org/doi/10.1145/3313831.3376730",
    },
  },
];

export default projectData;